import React from 'react'
import ContactForm from '../components/contactForm/ContactForm'
import OurTeam from '../components/ourTeam/OurTeam'
import Report from '../components/Report/Report'
import Hero from '../components/heroSection/Hero'
import Volunteer from '../components/volunteer/Volunteer'
import JoinUs from '../components/joinUs/JoinUs'
import CookieConsent from "react-cookie-consent";

declare let gtag: Function;

function index() {
	return (
		<>

			<Hero />
			<Volunteer />
			<JoinUs />
			<Report />
			<OurTeam />
			<ContactForm />
			<CookieConsent
				location="bottom"
				buttonText="Sutinku"
				style={{ background: "#2B373B" }}
				buttonStyle={{ color: 'white', background: "#0E558F", fontSize: "16px" }}
				expires={150}
				enableDeclineButton
				onAccept={() => {
					gtag('consent', 'update', {
						'ad_storage': 'granted',
						'ad_user_data': 'granted',
						'ad_personalization': 'granted',
						'analytics_storage': 'granted'
					});
				}}
				onDecline={() => {
					gtag('consent', 'update', {
						'ad_storage': 'denied',
						'ad_user_data': 'denied',
						'ad_personalization': 'denied',
						'analytics_storage': 'denied'
					});
				}}
				declineButtonText={"Nesutinku"}
			>
				Informuojame, kad Savanoriai vaikams platformoje mes naudojame slapukus. Jokia su slapukais susijusi informacija nėra naudojama Jūsų tapatybei nustatyti.
				<a target="_target" href="https://drive.google.com/file/d/1c6T_Op1lTWk4KXDfr5mc9n5-12ae-aGK/view?usp=sharing">
					&nbsp;<b>Skaityti plačiau.</b>
				</a>
			</CookieConsent>
		</>
	)
}

export default index